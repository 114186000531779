<template>
    <b-modal ref="modal_info_padrao"
             modal-class="modal_conversa"
             v-model="exibe"
             size="xl"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             hide-footer
             style="min-height: 150px;"
             >
        
        <div  style="width: 100%; display: block; text-align: center; ">
            <b-icon icon="arrow-clockwise" animation="spin" font-scale="4" id="carregamento_animado" style="width: 46px; color: #19b1bd; margin: 0px auto; display: none; "></b-icon>
            <span id="avisos" style="color: red; font-family: Verdana; font-size: 12px;position: absolute" ></span>
        </div>
        
        <div class="container-fluid modal-painel mx-auto h-100" id="container_info_orcamento">
            <h3 class="modal-titulo mb-3"> Informações do protocolo  <span style="font-size:15px;font-weight:bold;">{{dadosConversa.Protocolo}} </span> </h3>
            <div class="row">
                <div class="col">
                    <span style="font-size:15px;font-weight:bold">Cliente </span>
                    <table style="width: 100%;font-size:13px;">
                        <tr>
                            <td><b>Nome:</b> {{ clienteConversa? clienteConversa.RazaoSocial : '' }}</td>
                            <td><b>Documento:</b> {{ clienteConversa? clienteConversa.CnpjCpf : '' }}</td>
                        </tr>
                        <tr class="linha_par">
                            <td><b>Contato:</b> {{ clienteConversa? clienteConversa.NomeContato : '' }}</td>
                            <td><b>UF:</b> {{ clienteConversa? clienteConversa.UF : '' }}</td>
                        </tr>
                        <tr>
                            <td><b>Municipio:</b> {{ clienteConversa? clienteConversa.Municipio : '' }}</td>
                            <td><b>Cep:</b> {{ clienteConversa? clienteConversa.Cep : '' }}</td>
                        </tr>
                        <tr class="linha_par">
                            <td><b>Endereco:</b> {{ clienteConversa? clienteConversa.Endereco : '' }}</td>
                            <td><b>Numero:</b> {{ clienteConversa? clienteConversa.EnderecoNumero : '' }}</td>
                        </tr>
                        <tr>
                            <td><b>Complemento:</b> {{ clienteConversa? clienteConversa.Complemento : '' }}</td>
                            <td></td>
                        </tr>
                    </table>

                </div>
                <br />
            </div>
            <div class="row" style="margin-top:20px;">
                <div class="col">
                    <span style="font-size:15px;font-weight:bold">Produto(s) </span>
                    <table style="width: 100%;font-size:13px;">
                        <tr v-for="(item, index) in produtos[0].Produtos" :class="index%2 ? 'linha_par': ''">
                            <td><b>Nome:</b> {{ item.nome}} </td>
                            <td><b>Quantidade:</b> {{ item.qnt }}</td>
                            <td><b>Valor:</b> {{ item.valor.toFixed(2) }}</td>
                            <td><b>Total:</b> {{ (item.qnt * item.valor).toFixed(2) }}</td>
                        </tr>
                        <tr>
                            <td colspan="3" style="margin-top: 25px; display: block; background: #e8e8e8;"><b>Total:</b> {{calcula_total(produtos[0].Produtos)}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row" style="margin: 20px 0px 35px;">
                <div class="col-10">
                    <span style="font-size:15px;font-weight:bold">Observação </span><br>
                    <div>
                        <b-form-textarea id="textarea-no-resize"
                                         placeholder="Observação"
                                         rows="3"
                                         no-resize
                                         v-model="texto_observacao"></b-form-textarea>
                    </div>
                </div>
                <div class="col">
                    <span style="float: right; line-height: 1.3;" class="btn_acoes_info" @click="salvar_obs_orcamento(produtos[0].orcamentoId)">Salvar</span>
                </div>
            </div>
        </div>
    </b-modal>

</template>

<script>
    import axios from 'axios'
    import ClienteServices from '@/assets/js/services/ClienteServices'
    import DadosAtendimentoGuiaHistorico from '@/components/atendimento/DadosAtendimentoGuiaHistorico.vue'
    export default {
        name: 'ModalInfo',
        components: {
            ClienteServices,
            DadosAtendimentoGuiaHistorico
        },
        props: {
            dadosConversa: ''
        },

        data() {
            return {
                exibeCliente: false,
                exibe: false,
                dados: null,
                produtos: [{ Produtos: [] }],
                clienteConversa: {
                    type: Object,
                    default: null
                },
                texto_observacao:''
            }
        },
        watch: {
            dadosConversa() {
                //this.carregarProdutos(this.dadosConversa.Id)
                this.buscaCliente(this.dadosConversa.ClienteId)
            }
        },
        computed: {
           
            carregar: function () {
                //this.carregarProdutos(this.dadosConversa.Id)
            },
            
        },
        methods: {
            show() {
                this.exibe = true
            },
            hide() {
                this.exibe = false

            },
            setDados(data) {
                this.dados = data;
            },
            async buscaCliente(id) {
                this.clienteConversa = null;
                this.exibeCliente = false
                if (id != null && id != "00000000-0000-0000-0000-000000000000") {
                    this.clienteConversa = await ClienteServices.getById(id);
                    this.exibeCliente = true;
                }
            },
            // carregarProdutos(id) {
            //     axios.get('/api/produtos/CarregaEdicaoOrcamento/' + id)
            //         .then((response) => {
            //             $("#caixa_carregamento").show();
            //             this.produtos = response.data;

            //             this.texto_observacao = this.produtos[0].Obs
            //             $("#caixa_carregamento").hide();
            //         }, (error) => {
            //             console.log(error);
            //             $("#caixa_carregamento").hide()
            //         });
            // },
            calcula_total: function (prods) {
                var total = 0;
                for (var i in prods) {
                    total = total +  (prods[i].qnt * prods[i].valor)
                }
                return total.toFixed(2);
            },
            salvar_obs_orcamento: function (id) {
                console.log("orcamentoId", id)
                $("#container_info_orcamento").hide();
                $("#carregamento_animado").show();
                var dados = {
                    OrcamentoId: id,
                    texto: this.texto_observacao,
                    AtendimentoId: this.dadosConversa.Id
                };
                axios.post('/api/produtos/SalvarObservacao/',  dados)
                    .then((response) => {
                        $("#avisos").text("Observação salva com sucesso")
                        $("#container_info_orcamento").show();
                        //this.carregarProdutos(this.dadosConversa.Id)
                        $("#carregamento_animado").hide();
                        setTimeout(function () { $("#avisos").text("") }, 2000);
                    }, (error) => {
                        console.log(error);
                        $("#container_info_orcamento").show();
                        $("#carregamento_animado").hide();
                    });
               
            }

        },


    }
</script>
<style scoped>
    /* Sobrepoe o CSS original do modal, fazendo um modal FullScreen */
    table > tr > td {
        
    }

    .linha_par {
        background-color: #e8e8e8;
    }
    .btn_acoes_info {
        background-color: #39d325;
        color: #fff;
        cursor: pointer;
        width: 118px;
        text-align: center;
        margin-top: 5px;
        padding: 7px;
        position: relative;
        bottom: -68px;
        line-height: 4.5;
    }
</style>