
<template>
    <b-modal ref="ModalInfoFiltroKanban"
             modal-class="ModalInfoFiltroKanban"
             dialog-class="modalInfomacoesKanbanCP"
             content-class="contentModalInfomacoesKanbanCP"
             size="xl"
             v-model="exibe"
             body-class="app-modal-custom-header"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             title="Detalhes"
             @hide="limparModal()"
             hide-footer>
       
        <div class="container-fluid modal-painel mx-auto h-90" style="margin-bottom:50px;">
            <div class="row">
                <div class="col" style="margin-left:15px">
                    <div class="h-100">
                        <b-row id="boxDetalhesRelatorio" class="px-2 py-2 " style="height: calc(95vh - 150px)">
                            <b-col class="h-100">
                                <div id="menu_detalhes">
                                    <div class="justify-items-between" style="float: right; margin: 10px 0px;">
                                        <i class="fas fa-circle-notch fa-spin" v-if="exportando" small style="margin-left:7px;"></i>
                                        <botao-padrao :disabled="exportando" texto="Exportar" class="tabela-relatorios-botao-exportar" @click="exportarInfoFiltroKanban" />
                                    </div>
                                </div>
                                <div class="app-scroll-custom pt-0 insert_scroll  h-75" style="overflow-y: scroll; border: 1px solid #e8e8e8; background: #FFF; font-size:13px;">
                                    <div style="background-color: #e8e8e8; padding: 10px; margin: 10px;">
                                        <b-row style="text-align:left">
                                            <b-col><b>Protocolo</b></b-col>
                                            <b-col><b>Cliente</b></b-col>
                                            <b-col><b>Iniciado</b></b-col>
                                            <b-col><b>Valor orçado</b></b-col>
                                        </b-row>
                                    </div>
                                    <div v-for="(item, index) in detalhes[0]?.Secundario[0].AtendimentosCardsIds" style="background-color: #e8e8e8; padding: 10px; margin: 10px; ">
                                        <b-row style="text-align:left">
                                            <b-col style="color:#710606">{{item.DadosCard.Protocolo}}</b-col>
                                            <b-col> {{item.DadosCard.ClienteNome}} </b-col>
                                            <b-col>{{item.DadosCard.Inclusao}} </b-col>
                                            <b-col>{{total[item.AtendimentoId]}} </b-col>
                                        </b-row>
                                    </div>
                                </div>

                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div style="width: 97%; position: absolute; bottom: 100px; margin: 0px auto; left: 25px;">
                    <span><b>Valor total</b> : {{
                        montante.toFixed(2)
                    }}</span>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import DadosAtendimentoGuiaHistorico from '@/components/atendimento/DadosAtendimentoGuiaHistorico.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue';
    // Services

    import AtendimentoServices from '@/assets/js/services/AtendimentoServices'
    import RelatoriosCunsultasServices from '@/assets/js/services/RelatoriosConsultasServices'
    import axios from "axios";

    export default {
        name: 'ModalInfoFiltroKanban',
        components: {

            DadosAtendimentoGuiaHistorico,
            AtendimentoServices,
            RelatoriosCunsultasServices,
            InputPadrao,
            InputSelect, BotaoPadrao
        },
        props: {
            detalhes: {
                AtendimentosCardsIds:[{
                    Total : [{
                        ValorComDesconto : 0
                    }],
                    ValorCliente:0,
                    DadosCard:[]
                }],
                Coluna: null,
                Cor: null,
                IdCabecalho: null,
                Ordem: 0,
                QntAtendimentos: 0            
            }


        },
        data() {
            return {

                exibeCliente: false,
                exibe: false,
                dados: null,
                montante: 0,
                total:[],
                exportando: false 
            }
        },
        watch: {},
        created() { 
            this.realFormat();
        },
        methods: {
            realFormat() {
                this.detalhes[0]?.Secundario[0].AtendimentosCardsIds.forEach((atendCards) =>{
                    if(!atendCards.Total){
                        atendCards.Total = 0;
                    }
                    if (!Array.isArray(atendCards.Total)) {
                        this.total[atendCards.AtendimentoId] = 0;
                    }
                    else{
                        this.total[atendCards.AtendimentoId] = atendCards.Total.reduce((accumulated, item) => {
                           var valorComDesconto = item.ValorComDesconto || 0;
                            var descontoTotal = parseFloat(atendCards.DescontoTotal) || 0;
//
                            return accumulated + (valorComDesconto * (1 - descontoTotal * 0.01));
                        }, 0);
                        atendCards.DadosCard.ValorCliente =  this.total[atendCards.AtendimentoId]
                    }
                    this.montante += this.total[atendCards.AtendimentoId]; 
                    this.total[atendCards.AtendimentoId] = Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL"
                    }).format(this.total[atendCards.AtendimentoId]);
                })
            },
            calcTotalAmount() {

            },
            show() {
                this.$nextTick(() => {
                    this.realFormat();

                    this.exibe = true;  
                })
            },

            limparModal() {
                this.exibe = false;
                this.dados = null;
                this.montante = 0;
                this.total = [];

            },

            async exportarInfoFiltroKanban() {
                this.exportando = true;
                
                var query = this.detalhes[0]?.Secundario[0].AtendimentosCardsIds.map((Cartao) => Cartao.DadosCard);

                const atraso = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                return axios.post(`/api/relatorios/ExportarInfoFiltroKanban/`,query, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                }).then(res => {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([res.data]))
                    link.download = `relatorio_DetalhesInfoFiltroKanban_${Date.now()}.xlsx`
                    link.click();
                    link.remove();
                    $("#caixa_carregamento").hide();
                    this.exportando = false
                }).catch(err => {
                    console.log(err);
                });
            }
        },
        created() {
            this.currentAba = 1;
        }
    }
</script>
<style>

</style>
<style scoped>
    .caixa-botao{
        background-color: var(--cor-primaria-cliente);
    }
    #menuAtividadeCP {
        display: table;
        width: 100%;
    }

    .modalInfomacoesKanbanCP {
        display: flex;
        height: 90vh;
        max-width: 97% !important;
    }

    .contentModalInfomacoesKanbanCP {
        height: 100%;
        width: 98%;
        margin: 0px auto;
    }

    .footer_data {
        float: right
    }
    #menu_detalhes {
        width: 100%;
        display: table
    }

    #boxDetalhesRelatorio {
        background-color: #e8e8e8;
        cursor: default;
    }



    .btn_menu_conv_padrao {
        color: #FFF;
        padding: 5px;
        background-color: #88A4CA;
        cursor: pointer;
        width: 72px;
        float: right;
        margin-bottom: 6px;
        text-align: center;
    }

        .btn_menu_conv_padrao:hover {
            background-color: var(--cinza-5)
        }





    .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }


    #btn_salva_obs {
        background-color: #19b1bd;
        color: #FFF;
        width: 100%;
        cursor: pointer;
        text-align: center;
        padding: 7px;
        display: block;
        position: relative;
        bottom: 0px;
    }
</style>