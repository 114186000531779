<template>
    <div class="w-100 h-100">
        <div id="kanban-page" class="w-100 h-100">
            <Breadcrumb :titulo="(viewModes[viewMode] || { title: 'Erro' }).title" :items="item" backgroundColor="#000000" />
            <b-modal ref="modal_aviso_kanban" hide-footer title="Aviso">
                <div class="d-block" style=" margin-bottom: 45px;">
                    A coluna escolhida não pertence ao KANBAN, deseja relmente enviar para a tabulação ecolhida?
                </div>
                <botao-modal-ok type="submit" class="float-right ml-2" style="width:28%" @click="selectMudaColunaContinue()">Ok</botao-modal-ok>
                <botao-modal-cancel type="button" class="float-right ml-2" style="width:28%" @click="cancelarModalKanban('modal_aviso_kanban')"> Cancelar </botao-modal-cancel>
            </b-modal>

            <ModalConversaPadrao ref="modal_coversa_padrao"
                                 :dadosConversa="dadosConversa"
                                 :tabulacaoId="TabulacaoId"
                                 :onClienteChange="() => {
                                        columns = [];
                                        carregarColunasKanban(tabulacaoSelecionada);
                                    }"
                                 style="display:contents" />


            <ModalColorPicker ref="modal_color_picker" />



            <ModalInfo ref="modal_info_padrao"
                       :dadosConversa="dadosConversa" />
            <ModalInfoFiltroKanban ref="ModalInfoFiltroKanban"
                                   :detalhes="filtroDetalhes" />
            <ModalCliente ref="modalCliente"
                          :cliente="clienteAtendimento"
                          :exibeCamposExtras="true"
                          @salvar="salvarCliente" />

            <modalClienteExistente ref="modalClienteExistente"
                                   :ClientesExistentes="clientes"
                                   @salvar="salvarClientesExistente" />


            <section class="w-100 container-kanban p-2">
                <b-row class="menu-cards">
                    <b-col id="kanban-navL-dashboard">
                        <b-row id="kanbTab-openers">
                            <div v-for="(item, i) in kanbansDisponiveis"
                                 @click="tabulacaoSelecionada == item.Id ? 0 : carregarColunasKanban(item.Id, true,null);"
                                 :class="tabulacaoSelecionada == item.Id ? 'active' : ''"
                                 class="px-4 py-2">
                                <span class="text-truncate">{{item.Nome || '_'}}</span>
                            </div>
                        </b-row>
                    </b-col>
                </b-row>
                <div style="background-color: var(--cinza-3);" class="container_principal row-md-auto">
                    <div v-if="!kanbanIndisponivel" style="background-color: var(--cinza-3);" class="container_principal row-md-auto">
                        <div id="menu_kanban">
                            <div class="float-left">
                                <button v-if="viewMode == 'kanban' && columns.length != 0" class="button primary-button" @click="criar_card(item, tabulacaoSelecionada)">
                                    <i class="fas fa-plus mr-1"></i>
                                    Criar Card
                                </button>
                              

                            </div>
                            <div class="float-right">
                                <div class="input-search px-2 py-1 mr-3">
                                    <input type="text"
                                           ref="input-search"
                                           placeholder="Bucar clientes"
                                           autocomplete="off"
                                           v-model="StringbuscaKanban"
                                           @keyup.enter="buscaClientes" />
                                    <i v-if="!StringbuscaKanban" class="fas fa-search fa-fw"></i>
                                    <i v-else class="fas fa-times fa-fw" @click="limparPesquisa"></i>
                                </div>
                                <button v-for="item in Object.keys(viewModes)"
                                        v-if="viewMode != item && viewModes[item].available"
                                        class="button primary-button"
                                        @click="changeViewMode(item)">
                                    <i v-if="viewModes[item].fasIcon" :class="`fas ${viewModes[item].fasIcon} mr-1`"></i>
                                    <b-icon v-else-if="viewModes[item].bIcon" :icon="viewModes[item].bIcon" class="mr-1"></b-icon>
                                    {{viewModes[item].title}}
                                </button>
                                <b-dropdown v-if="!dadosUsuarioAutenticado.KanbanIdividual" text="Pipeline" class="ml-2" id="btn_pipeline" toggle-class="btn_pipeline box-cliente" right>
                                    <div id="menu_pipeline" style="width:250px;display:block;">
                                        <b-dropdown-text class="w-100 ajuste_dropdown" style="padding:0 !important">
                                            <b-card no-body>
                                                <b-tabs>
                                                    <b-tab title="Proprietario">
                                                        <div class="w-100 app-scroll-custom pt-0 insert_scroll" style="display:block; height:200px">
                                                            <b-dropdown-item href="#" style="z-index: 10;" value="0" @click="selecionarUsuarioKanban(null)">
                                                                <i class="fas fa-user"></i> Todos
                                                            </b-dropdown-item>
                                                            <b-dropdown-item href="#" v-for="(item, i) in listagemUsuarios" style="z-index: 10;" :value="item.Id" @click="selecionarUsuarioKanban(item.Id)">
                                                                <i class="fas fa-user"></i> {{item.Nome}}
                                                                <span style="font-size: 12px; color: forestgreen; margin-left: 10px;">{{usuario.Id == item.Id ? '(Você)': ''}}</span>
                                                            </b-dropdown-item>
                                                        </div>
                                                    </b-tab>
                                                    <!--disabled-->
                                                    <b-tab title="Filtros">
                                                        <div class="w-100 app-scroll-custom pt-0 insert_scroll" style="display:block; height:200px">
                                                            <b-dropdown-item href="#" style="z-index: 10;" value="0" @click="selecionarFiltroKaban(null)">
                                                                <i class="fas fa-filter"></i> Todos
                                                            </b-dropdown-item>
                                                            <b-dropdown-item href="#" v-for="(item, i) in filtros" style="z-index: 10;" :value="item.id" @click="selecionarFiltroKaban(tabulacaoSelecionada, item.id)">
                                                                <i class="fas fa-filter"></i> {{item.titulo}}

                                                            </b-dropdown-item>
                                                        </div>
                                                    </b-tab>
                                                </b-tabs>
                                            </b-card>
                                        </b-dropdown-text>
                                    </div>
                                </b-dropdown>
                            </div>
                        </div>
                        <div id="colunas-kanban" v-if="viewMode == 'kanban'" class="d-flex flex-nowrap app-scroll-custom pt-0 insert_scroll col-sm-auto row-sm-auto" style="overflow-y: hidden; overflow-x: auto;">
                            <div v-for="(column, Ordem) in columns"
                                 :key="column.IdCabecalho"
                                 :id="column.IdCabecalho"
                                 :style="{ 'background-color': column.Cor}"
                                 class="bg-gray-100 pb-3 mr-3 column-width">
                                <div class="cabecalhokanban d-flex justify-content-md-between align-items-center rounded-pill px-3 rounded-pill"
                                     style="z-index: 10; width: 315px;background-color: #e8e8e8; height: 50px;"
                                     :style="{ 'background-color': column.Cor}">
                                    <div class="flex justify-between items-center mb-2 titulo_coluna_funil text-truncate" :title="column.Coluna">
                                        <label @click="mostraColorPicker(column)" :for="`color-picker-${column.IdCabecalho}`" class="select_color btn-default">&nbsp<i class="fas fa-paint-brush fa-1x"></i></label>
                                        {{column.Coluna}}
                                        <span class="qnt_itens_funil"> {{column.AtendimentosCardsIds.length}} negócios  - {{realFormat(montante[column.IdCabecalho])}}</span>
                                    </div>


                                </div>
                                <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
                                <draggable :list="column.AtendimentosCardsIds" :animation="200" ghost-class="ghost-card" group="tasks"
                                           :move="checkMove"
                                           :class="[ column.AtendimentosCardsIds ? '' : 'h-100' , 'card_canal']"
                                           @change="mudarColuna($event)"
                                           @start="drag=true" @end="drag=false"
                                           class="insert_scroll  app-scroll-custom pt-0 ajuste_coluna row-md-auto ">

                                    <!--CRIAÇÂO DE CARDS NO KANBAN  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>-->

                                    <div class="card-task bg-white shadow px-3 pt-3 pb-5_2 border border-white" :class="stateClass"
                                         v-for="(atendimento) in column.AtendimentosCardsIds"
                                         :key="atendimento.AtendimentoId"
                                         :id="'cardIdcardId' + atendimento.AtendimentoId">
                                        <div class="flex justify-between protocolo_card">
                                            <span class="card_kanban_protocolo">#{{atendimento.DadosCard.Protocolo}}</span>
                                            <!--Ícone do modal ficava aqui-->
                                        </div>
                                        <div class="flex justify-between items-center mb-2 card_principal">
                                            <span v-if="atendimento.DadosCard.ClienteNome != null"
                                                  class="card_funil_nome_cliente"
                                                  @click="abrirModalCliente(atendimento.DadosCard)">
                                                {{atendimento.DadosCard.ClienteNome}}
                                            </span>

                                            <span class="card_funil_nome_usuario">
                                                Por: <b>{{atendimento.DadosCard.AtendenteNome == null ? 'Não Informado' : atendimento.DadosCard.AtendenteNome}}</b>
                                                &nbsp;<i id="botaoTrocaIcon" class="fas fa-retweet fa-fw " @click="abrirModalTroca(atendimento.DadosCard)"></i>
                                            </span>
                                            <span class="card_funil_data_inicio">
                                                Iniciado em  {{atendimento.DadosCard.Inclusao}}<br />

                                            </span>
                                            <span v-if="atendimento.DadosCard.DataRetorno != null" class="card_funil_data_alterada">Alterado em {{atendimento.DadosCard.DataRetorno}}</span>
                                            <div class="my-1">
                                                <select class="select_kanban" v-model="selectTab" @change="selectMudaColunaKanban(atendimento)">
                                                    <option value="0" disabled>Trocar para a coluna:</option>
                                                    <option :value="item" v-for="(item, i) in selectOptionCard" v-if="column.TabulacaoItemId != item.AtabulacaoItemId">{{item.Titulo}} </option>
                                                </select>
                                            </div>
                                            <div class="icones_modals d-flex justify-content-between">
                                                <div class="d-flex justify-content-start">
                                                    <i class="fas fa-shopping-cart fa-fw fa-1x i_modal_orcamento botaoOrcamento" @click="abrirModalOrcamento(atendimento.DadosCard)" style="margin-right: 5px; "></i>
                                                    <span style="font-size: 12px;">{{realFormatDesconto(atendimento.Total,atendimento.DescontoTotal)}}</span>
                                                </div>
                                                {{  }}
                                                <div class="d-flex justify-content-end">
                                                    <button class="botao-clienteAdd add-cli" v-if="!atendimento.DadosCard.ClienteId" @blur="closeAddMenu(atendimento.AtendimentoId)" @click="openAddMenu(atendimento.AtendimentoId, atendimento.DadosCard)">
                                                        <i id="botaoInfoIcon" class="abrir_modal_cli fas fa-user-plus fa-fw fa-1x">
                                                        </i>
                                                        <ContextMenu :ref="atendimento.AtendimentoId" :actions="contextMenuAddActions" :useFixedPosition="true" />
                                                    </button>
                                                    <button v-else class="botao-clienteAdd add-cli" @click="abrirModalCliente(atendimento.DadosCard)">
                                                        <i id="botaoInfoIcon" class="abrir_modal_cli fas fa-user-edit fa-fw fa-1x">
                                                        </i>
                                                    </button>
                                                    <i id="botaoInfoIcon" class="fas fa-info-circle fa-fw conversa_funil" @click="abrirConversa(atendimento.DadosCard)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </draggable>
                            </div>
                        </div>
                        <div v-else-if="viewMode == 'funil-de-vendas'" id="funil-de-vendas">
                            <div v-for="(layer, index) in columns"
                                 :class="[
                                 `funnel-layer${layer.AtendimentosCardsIds.length>
                                0 ? ' available' : ''}`
                                // Adiciona a classe 'funnel-layer-last' apenas ao último elemento
                                ]"
                                :style="{
                                'width': `${70 - index * 30 / (columns.length - 1)}vw`,
                                'background-color': `${layer.Cor === '#858585' ? `hsl(${index * 120 / (columns.length - 1)}, 60%, 50%)` : layer.Cor}`
                                }"
                                @click="layer.AtendimentosCardsIds.length > 0 ? selecionarFiltroKaban(tabulacaoSelecionada,layer.AtendimentosCardsIds[0]?.TabulacaoItemId ?? null) : null">
                                <div class="funnel-title text-truncate">{{layer.Coluna}}</div>
                                <div class="funnel-info">
                                    <div class="funnel-info-quantity">{{layer.AtendimentosCardsIds.length}}</div>
                                    <div class="funnel-info-amount">
                                        {{realFormat(montante[layer.AtendimentosCardsIds[0]?.TabulacaoItemId])}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else id="modo-visualizacao-invalido">
                            Modo de visualização inválido.
                        </div>
                    </div>
                    <div v-else>
                        <div class="alert alert-info text-center" role="alert">
                            <h4 class="alert-heading">Kanban indisponível</h4>
                            <p>Não há tabulações disponíveis ou você não possui permissão para acessar</p>
                        </div>
                    </div>
                </div>
            </section>
            <novoClienteModal ref="novo-ClienteModal" />
            <ModalSelecaoCliente ref="selecao-cliente" />
            <modalOrcamento ref="modalOrcamento" @salvarOrcamento="buscaKanbansTabulacoes()" />
            <ModalSelecaoAtendente ref="ModalSelecaoAtendente" @trocaRealizada="trocaRealizada" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import axios from 'axios'
    import novoClienteModal from '@/components/clienteNewPage/novoClienteModal.vue'
    import draggable from "vuedraggable";
    import AtendimentoServices from '@/assets/js/services/AtendimentoServices'
    import TabulacaoServices from '@/assets/js/services/TabulacaoServices'
    import ClienteServices from '@/assets/js/services/ClienteServices'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import TaskCard from "@/components/TaskCard.vue";
    import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao.vue'
    import ModalInfo from '@/components/consultas/ModalInfoAtendimentoPadrao.vue';
    import ModalColorPicker from '@/components/modals/ModalColorPicker2.vue';
    import ModalInfoFiltroKanban from '@/components/consultas/ModalInfoFiltroKanban.vue';
    import ModalSelecaoAtendente from '@/components/modals/ModalSelecaoAtendente.vue';
    import ModalSelecaoCliente from "@/components/modals/ModalSelecaoCliente";
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import InputPadrao from '@/components/inputs/InputPadrao.vue';
    import InputColor from '@/components/inputs/InputColor.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue';
    import ContextMenu from "@/components/menu-contexto/Default";
    const ModalCliente = () => import('@/components/cliente/ModalCliente.vue');
    import modalOrcamento from '@/components/kanban/ModalOrcamento.vue';
    const ModalClienteExistente = () => import('@/components/cliente/ModalClienteExistente.vue');


    export default {
        name: 'KanbanPage',
        components: {
            Breadcrumb,
            draggable,
            TaskCard,
            ContextMenu,
            ModalConversaPadrao,
            ModalInfo,
            ModalColorPicker,
            ModalCliente,
            modalOrcamento,
            ModalClienteExistente,
            BotaoModalOk,
            BotaoModalCancel,
            novoClienteModal,
            InputPadrao,
            ModalSelecaoCliente,
            InputColor,
            InputSelect,
            ModalInfoFiltroKanban,
            ModalSelecaoAtendente
        },
        data: function () {
            return {
                item: [{
                    id: '2',
                    classe: 'fas fa-table',
                    texto: 'kanban',
                    link: '/kanban'
                }],
                kanbansDisponiveis: [{ Id: "", Nome: "" }],
                columns: [
                    {
                        Consulta: [{ Atendimento: '', ClienteNome: '', Id: '', IdCabecalho: '', Inclusao: '', Protocolo: '', UltimaAlteracao: '', ClienteId: '' }], AtendimentosCardsIds: [{ visibleList: false, TabulacaoItemId: '', Cor: '', ValorCliente: 0, Total: [{ ValorComDesconto: 0 }], DescontoTotal: 0, DadosCard: [{ Protocolo: '', Id: '', ClienteNome: '', AtendimentoTabulacaoId: '', AtendenteNome: '' }] }]
                    },
                ],
                stateClass: "loading",
                IdRespostaTab: '',
                AtendimentoId: '',
                StringbuscaKanban: '',
                IdAtendimentoTab: '',
                TabulacaoId: '',
                novoCardColuna: '',
                lista_coluna_modificada: null,
                card_modificado: null,
                dadosConversa: {},
                clienteAtendimento: {},
                clientes: [{ RazaoSocial: '', CpfCnpj: '', Id: '' }],
                modulosEmpresa: 0,
                rhAtivo: false,
                comercialAtivo: false,
                atendimentoId: '',
                tabulacaoSelecionada: 0,
                selectTab: 0,
                atSelecionado: null,
                movendo: false,
                dadosCab: '',
                dadosAtualizar: '',
                selectedColor: '',
                kanbanIndisponivel: false,
                tabIndex: 0,
                montante: [],
                usuarioKanbanSelecionado: null,
                filtros: [],
                arrayValor: [{ ValorComDesconto: 0 }],
                filtroDetalhes: [],
                selectOptionCard: [],
                listagemUsuarios :[],
                viewModes: {
                    "kanban": {
                        title: "Kanban",
                        fasIcon: "fas fa-table",
                        available: true
                    },
                    "funil-de-vendas": {
                        title: "Funil de Vendas",
                        fasIcon: "fas fa-filter",
                        available: false
                    }
                },
                contextMenuAddActions: {},
                viewMode: null
            }
        },
        created() {
           
            this.viewMode = this.$route.params.view ?? "kanban";
            $("#caixa_carregamento").show();
            this.buscaKanbansTabulacoes();
            this.usuarios();
            
        },
        watch: {
            usuarios() {
                return this.usuarios();
            },

            empresaMod() {
                return this.empresaMod();
            },
            tabulacaoSelecionada() {
                let tabulacao = this.kanbansDisponiveis.filter(item => item.Id == this.tabulacaoSelecionada)[0];
                this.viewModes["funil-de-vendas"].available = tabulacao.kanbanPerfil == 1;
            }
        },
        computed: {
            ...mapGetters({
                enumOrigem: 'atendimento/enumOrigem',
                dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
                getUsuarioPorId: 'usuarios/getUsuarioById',
                ModulosDisponiveis: 'modulos_integracoes/modulosItegracoes',
                listaUsuarios: 'usuarios/listaUsuarios',

            }),
            

            usuario: function () {
                if (this.dadosUsuarioAutenticado) {
                    const dadosUsuarioAtivo = this.dadosUsuarioAutenticado
                    return {
                        Id: dadosUsuarioAtivo.usuarioId,
                        Perfil: dadosUsuarioAtivo.perfil,
                        EmpresaId: dadosUsuarioAtivo.empresaId,
                    };
                }
                return {};
            },
            empresaMod: function () {
                const mod = this.$store.getters['authentication/authDadosUsuario'];
                this.modulosEmpresa = mod.ModulosEmpresa == null ? "0" : mod.ModulosEmpresa;
                this.rhAtivo = this.modulosEmpresa.includes('2')
                this.comercialAtivo = this.modulosEmpresa.includes('6')
            },
        },
        methods: {
            ...mapActions({
                buscaEnumsAtendimentos: 'atendimento/buscaEnums',
                mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso',
            }),
            usuarios: function () {
                axios.get(`/api/usuario/lista-basica/`).then(response => {
                    this.listagemUsuarios = response.data;
                });
                
               
            },
            closeAddMenu(id) {
                if (this.$refs[id]) this.$refs[id][0].hide();
            },
            openAddMenu(id, dados) {
                this.contextMenuAddActions = {
                    novoCliente: {
                        label: "Vincular novo cliente",
                        fasIcon: "fa-user-plus",
                        action: () => {
                            this.abrirModalCliente(dados);
                        }
                    },
                    clienteExistnete: {
                        label: "Vincular cliente existente",
                        fasIcon: "fa-user-edit",
                        action: () => {
                            this.vincularClienteKanban(id);
                        }
                    }
                }
                this.contextMenuAddActions.novoCliente.disabled = false;
                this.contextMenuAddActions.clienteExistnete.disabled = false;
                if (this.$refs[id]) this.$refs[id][0].show();
            },
            realFormat(valor) {
                return Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                }).format(valor ?? 0);
            },
            realFormatDesconto(valor, desconto) {
                if (!valor) valor = [{ ValorComDesconto: 0 }];
                if (!desconto) desconto = 0;
                var total = 0;

                valor.map(objeto => {
                    total += objeto.ValorComDesconto;
                });
                total *= 1 - (desconto * 0.01)
                return Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                }).format(total);
            },
            selecionarUsuarioKanban: function (usuarioId) {
                this.columns = []
                this.usuarioKanbanSelecionado = usuarioId
                let selectNovo = this.kanbansDisponiveis.filter(f => f.Id == this.$route.params.id)[0] ?? this.kanbansDisponiveis[0]

                this.carregarColunasKanban(selectNovo.Id, true, null);
            },
            async buscaKanbansTabulacoes() {
                this.kanbansDisponiveis = await TabulacaoServices.buscaKanbansTabulacoes();

                if (this.kanbansDisponiveis.length > 0) {
                    let kanbanAtual = this.kanbansDisponiveis.filter(f => f.Id == this.$route.params.id)[0] ?? this.kanbansDisponiveis[0]
                    this.carregarColunasKanban(kanbanAtual.Id, true, null);
                }
                else {
                    this.kanbanIndisponivel = true;
                    $("#caixa_carregamento").hide();
                }
            },
            buscaClientes() {
                if (this.kanbansDisponiveis.length > 0) {
                    this.carregarColunasKanban(this.tabulacaoSelecionada, true, null, true);
                }
            },
            limparPesquisa() {
                this.StringbuscaKanban = '';
                this.carregarColunasKanban(this.tabulacaoSelecionada, true, null, false);
            },
            atualizarDados: function () {
                this.columns = []
                this.carregarColunasKanban(this.tabulacaoSelecionada, true, null);
            },
            checkMove: async function (evt, coluna) {
                this.IdRespostaTab = evt.to?.parentElement.id;

                this.movendo = true;

                this.card_modificado = evt.draggedContext.element;

                return;
            },
            mudarColuna: function (evt) {
                if (evt && evt.added) {
                    let colunaAntiga = evt.added?.element.TabulacaoItemId;
                    let colunaNova = this.IdRespostaTab;

                    let dados = {
                        TabulcaoItemId: this.IdRespostaTab,
                        AtendimentoTabulacaoId: this.card_modificado.DadosCard.AtendimentoTabulacaoId,
                        Origem: "mudaColuna",
                        AtendimentoId: evt.added?.element.AtendimentoId,
                        TabulacaId: this.card_modificado.DadosCard.AtendimentoTabulacaoId
                        //AtendimentoId: atendId
                    }
                    axios.post('/api/tabulacao/modificaKanban', dados).then(resposta => {
                        this.novoCardColuna = resposta.data;
                        if (colunaAntiga) {
                            let coluna = this.columns.find(f => f.IdCabecalho == colunaNova);
                            let indexCardAntigo = coluna.AtendimentosCardsIds.findIndex(x => x === this.card_modificado);
                            coluna.AtendimentosCardsIds.splice(indexCardAntigo, 1)
                            coluna.AtendimentosCardsIds.splice(0, 0, this.novoCardColuna)
                            this.montante = [];
                            this.calcTotalAmount();
                        }
                    }).catch(erro => {
                        console.error(erro);
                    })
                }
            },
            selectMudaColunaKanban: function (dadosAtendimentoCard, coluna) {
                //this.tabulacaoSelecionada = this.selectTab;
                //Tabulação Id coluna antiga
                let tabulacaoIdAntiga = dadosAtendimentoCard.TabulacaoItemId;
                //selecionando coluna antiga para remoção
                let colunaAntiga = this.columns.find(f => f.IdCabecalho == tabulacaoIdAntiga);
                //Tabulação Id coluna Nova
                let tabulacaoIdNovo = this.selectTab.AtabulacaoItemId;
                //selecionando coluna Nova para inclusão
                let colNova = this.columns.find(f => f.IdCabecalho == tabulacaoIdNovo);

                let dadosUpdate = {
                    TabulcaoItemId: tabulacaoIdNovo,
                    AtendimentoId: dadosAtendimentoCard.DadosCard.Id,
                    Origem: "mudaColuna",
                    AtendimentoTabulacaoId: dadosAtendimentoCard.DadosCard.AtendimentoTabulacaoId
                }
                axios.post('/api/tabulacao/modificaKanban', dadosUpdate).then(resposta => {
                    let indexCardAntigo = colunaAntiga.AtendimentosCardsIds.findIndex(x => x === dadosAtendimentoCard);
                    colunaAntiga.AtendimentosCardsIds.splice(indexCardAntigo, 1);

                    colNova.AtendimentosCardsIds.unshift(resposta.data);
                    this.montante = [];
                    this.calcTotalAmount();
                })
                //this.carregarColunasKanban(this.tabulacaoSelecionada,true,null)
                this.selectTab = null;
            },

            cancelarModalKanban: function (modal) {
                this.$refs[modal].hide()

            },
            carregarColunasKanban: function (tabulacaoId, carrega, colunaId, buscandoCliente) {
                if (this.$route.params.id != tabulacaoId) this.$router.replace({
                    path: `/kanban/${tabulacaoId}${this.viewMode && this.viewMode != "kanban" ? `/${viewMode}` : ""}`
                });
                if (carrega) $("#caixa_carregamento").show();

                this.tabulacaoSelecionada = tabulacaoId;
                this.selectTab = tabulacaoId;
                let urlColuna = '/api/kanban/busca-kanban/';
                let data = {
                    ColunaId: colunaId,
                    TabulacaoId: tabulacaoId,
                    UsuarioId: this.usuarioKanbanSelecionado
                }
                axios.post(urlColuna, data)
                    .then((response) => {
                        if (response.data && response.data.length == 0) return;
                        this.$nextTick(() => {
                            this.columns = response.data[0]?.Secundario
                            this.calcTotalAmount()
                            this.filtros = this.columns.map(m => ({ titulo: m.Coluna, id: m.IdCabecalho, respostaTab: m.Coluna }));
                            this.columns.forEach(col => col.AtendimentosCardsIds.forEach(cards => cards.visibleList = false));
                            if (buscandoCliente && this.StringbuscaKanban) {
                                this.columns.forEach(col => {
                                    col.AtendimentosCardsIds = col.AtendimentosCardsIds.filter(card =>
                                        card.DadosCard.ClienteNome && card.DadosCard.ClienteNome.toLowerCase().includes(this.StringbuscaKanban.toLowerCase())
                                    );
                                });
                            }
                        })
                        this.$nextTick(() => {
                            this.selectOptionCard = this.columns.map(m => ({ Titulo: m.Coluna, AtabulacaoItemId: m.IdCabecalho }));
                        })
                        if (carrega) {
                            $("#caixa_carregamento").hide();
                        }
                        this.tabIndex = this.kanbansDisponiveis.indexOf(this.kanbansDisponiveis.filter(f => f.Id == tabulacaoId)[0])
                        $(".card-task.loading").removeClass("loading");
                    }, (error) => {
                        console.error(error);
                        $("#caixa_carregamento").hide();
                        $(".card-task.loading").removeClass("loading");
                        this.kanbanIndisponivel = true;
                        this.kanbansDisponiveis = null;
                    });
            },
            selecionarFiltroKaban: function (tabulacaoId, colunaId) {
                $("#caixa_carregamento").show();
                let urlColuna = '/api/kanban/busca-kanban/';
                let data = {
                    ColunaId: colunaId,
                    TabulacaoId: tabulacaoId,
                    UsuarioId: this.usuarioKanbanSelecionado
                }
                axios.post(urlColuna, data)
                    .then((response) => {
                        this.filtroDetalhes = response.data;
                        this.$refs.ModalInfoFiltroKanban.show();
                        $("#caixa_carregamento").hide();
                    })
                    .catch((error) => {
                        console.error(error.response);
                        $("#caixa_carregamento").hide();
                        this.kanbanIndisponivel = true;
                    });

            },

            criar_card: async function (item, tabId) {
                $("#caixa_carregamento").show();

                let retorno = await AtendimentoServices.AtendimentoAtivoSemPABX("criarCard", tabId, this.columns[0].IdCabecalho)
                await this.carregarColunasKanban(tabId, true, null)
                this.$bvToast.toast("Protocolo " + retorno.Protocolo + ' \n\r criado com sucesso', {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                });
                $("#caixa_carregamento").hide();
            },

            ordernar: function (items) {
                if (items == null) return null;
                return items.sort((a, b) => a.DadosCard.AlteracaoOrdem > b.DadosCard.AlteracaoOrdem ? -1 : (a.DadosCard.AlteracaoOrdem < b.DadosCard.AlteracaoOrdem ? 1 : 0));
            },
            abrirConversa: function (dadosCard) {
                //this.$emit("abrirConversa", this.task.Id, this.task.Protocolo, this.task.ClienteId, this.task.UsuarioLogado, this.task)
                const valor = { Id: dadosCard.Id, Protocolo: dadosCard.Protocolo, ClienteId: dadosCard.ClienteId, UsuarioLogado: this.usuario, Atendimento: dadosCard }
                this.dadosConversa = valor;
                this.$refs.modal_coversa_padrao.show();
            },
            vincularClienteKanban(id) {
                this.$refs["selecao-cliente"].selecionarCliente().then(clienteid => {
                    if (!clienteid) return;
                    let form = new FormData();
                    form.append("clienteid", clienteid);
                    axios.put(`api/atendimento/${id}/vincular-cliente`, form).then(() => {
                        this.buscaKanbansTabulacoes();
                    }).catch(error => {
                        if (error.response?.status == 409 && error.response?.data) {
                            this.$bvmodal.msgboxok(error.response?.data, {
                                title: "vínculo rejeitado pelo sistema",
                                centered: true,
                                size: "md",
                                buttonsize: "md",
                                okvariant: "danger",
                                oktitle: "entendi"
                            })
                            return;
                        }
                    });
                });
            },
            async abrirModalCliente(dadosCards) {
                if (dadosCards?.ClienteId) {
                    axios.get(`api/Cliente/${dadosCards?.ClienteId}`).then(cli => {
                        this.$refs["novo-ClienteModal"].iniciarModal(cli.data, dadosCards?.ClienteNome).then(res => {
                            const vinculoClienteAtendimento = {
                                'ClienteId': res.Cliente.Id,
                                'AtendimentoId': dadosCards.Id
                            }
                            axios.post(`api/Cliente/vinculo-atendimento`, vinculoClienteAtendimento).then(() => {
                                this.buscaKanbansTabulacoes();
                            })
                                .catch(error => {
                                    if (error.response?.status == 409 && error.response?.data) {
                                        this.$bvmodal.msgboxok(error.response?.data, {
                                            title: "vínculo rejeitado pelo sistema",
                                            centered: true,
                                            size: "md",
                                            buttonsize: "md",
                                            okvariant: "danger",
                                            oktitle: "entendi"
                                        })
                                        return;
                                    }
                                });
                        })
                    })
                }
                else {
                    this.$refs["novo-ClienteModal"].iniciarModal().then(res => {
                        const vinculoClienteAtendimento = {
                            'ClienteId': res.Cliente.Id,
                            'AtendimentoId': dadosCards.Id
                        }
                        axios.post(`api/Cliente/vinculo-atendimento`, vinculoClienteAtendimento).then(() => {
                            this.buscaKanbansTabulacoes();
                        })
                            .catch(error => {
                                if (error.response?.status == 409 && error.response?.data) {
                                    this.$bvmodal.msgboxok(error.response?.data, {
                                        title: "vínculo rejeitado pelo sistema",
                                        centered: true,
                                        size: "md",
                                        buttonsize: "md",
                                        okvariant: "danger",
                                        oktitle: "entendi"
                                    })
                                    return;
                                }
                            });
                    })
                }

            },
            async salvarCliente(cliente) {
                $(`#cardId${this.atSelecionado}`).addClass("loading");

                $("#caixa_carregamento").show();
                if (cliente.Id) {
                    await ClienteServices.update(cliente);
                    $(`#cardId${this.atSelecionado}`).removeClass("loading");
                }
                var response = await ClienteServices.post(cliente);
                if (response.Sucesso) {
                    await ClienteServices.vincularClienteAtendimento(response.Cliente.Id, this.atSelecionado);
                    this.atualizarDados(this.dadosAtualizar)
                    $(`#cardId${this.atSelecionado}`).removeClass("loading");
                    this.$bvToast.toast("Salvo com sucesso", {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'success',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    });
                }
                else {
                    this.$bvToast.toast(response.Message, {
                        title: `Aviso`,
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        variant: 'warning',
                        appendToast: false,
                        autoHideDelay: 3000,
                        noCloseButton: true
                    });
                }

                $("#caixa_carregamento").hide();
            },
            mostraColorPicker: function (coluna) {
                this.dadosCab = coluna;
                this.$refs.modal_color_picker.selecionarCor().
                    then(cor => {
                        axios.put('/api/tabulacaoItem/editaCor/' + coluna.IdCabecalho, { cor: cor }).catch(erro => console.error(erro))
                        if (typeof this.onColorChange == "function") {
                            this.$nextTick(() => {
                                this.onColorChange();
                            });
                        }
                        coluna.Cor = cor;
                    });
            },
            async salvarClientesExistente(clienteExistenteId) {
                $(".card-task.loading").removeClass("loading");

                $("#caixa_carregamento").show();
                this.$refs.modalClienteExistente.hide();
                await ClienteServices.vincularClienteAtendimento(clienteExistenteId, this.atendimentoId);
                $("#caixa_carregamento").hide();
                $(".card-task.loading").removeClass("loading");
                return "local"
            },
            vinculo: async function (vinculo) {
                $("#caixa_carregamento").show();
                await axios.post('/api/Vaga/VinclularVagaAtendimento/', vinculo)
                this.carregarColunasKanban(this.tabulacaoSelecionada, true, null)
                this.$bvToast.toast("Vinculo Efetuado", {
                    title: `Aviso`,
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    variant: 'success',
                    appendToast: false,
                    autoHideDelay: 3000,
                    noCloseButton: true
                });
                $("#caixa_carregamento").hide();
                $(".card-task.loading").removeClass("loading");
            },

            abrirModalOrcamento(dadosCard) {

                this.$refs["modalOrcamento"].iniciarModal(dadosCard).then(() => {
                    this.buscaKanbansTabulacoes();
                });
            },
            abrirModalTroca(dados) {
                this.$refs.ModalSelecaoAtendente.show(dados.Id).then((nome) => {
                    dados.AtendenteNome = nome;
                    this.$bvToast.toast('Usuario alterado com sucesso!', {
                        title: `Sucesso`,
                        variant: 'info',
                        solid: true
                    })
                });
            },

            changeViewMode(viewMode) {
                //this.atualizarDados();
                if (this.viewMode == viewMode) return;
                this.viewMode = viewMode;
                this.$router.replace({
                    path: `/kanban/${this.tabulacaoSelecionada}/${viewMode}`
                });
            },
            calcTotalAmount() {
                var totalvalordesc = 0;
                this.columns.forEach((column) => {
                    this.montante[column.IdCabecalho] = 0;
                    column.AtendimentosCardsIds.forEach((atendimento) => {
                        const tabulacaoItemId = atendimento.TabulacaoItemId;
                        if (atendimento.Total && atendimento.Total.length > 0) {
                            atendimento.Total.forEach((total) => {
                                var valorComDesconto = total.ValorComDesconto;

                                if (totalvalordesc != 0) {
                                    totalvalordesc += valorComDesconto;
                                } else {
                                    totalvalordesc = valorComDesconto;
                                }
                            })
                            this.montante[tabulacaoItemId] += totalvalordesc * (1 - atendimento.DescontoTotal * 0.01)
                            totalvalordesc = 0;
                        }
                    });
                })
            },
            async geraValor(id) {
                this.clienteConversa = await ClienteServices.getById(id);
                return clienteConversa.valor;
            },
            buscarOrcamento(dadosCard) {
                axios.get(`/api/produto/busca-orcamento/` + dadosCard.Id).then(response => {
                    this.itensOrcamento = response.data.OrcamentoProdutos ?? [];
                    this.orcamentoId = response.data.Id ?? null;
                }).catch(() => {

                }).then(() => {
                    if (this.orcamentoId) {
                        this.itensOrcamento.forEach(orcItem => {
                            axios.get(`/api/produto/` + orcItem.ProdutoId).then(res => {
                                this.ProdutosQueryNome.push(res.data.Nome);
                                this.ProdutosQuerySKU.push(res.data.SKU);
                            });
                        })
                    }
                    this.adicionarItem();
                });
            },
            trocaRealizada(atendimentoId, atendente_selecionado) {
                let dados = this.columns.find(f => f.AtendimentosCardsIds[0].AtendimentoId == atendimentoId)
            }
        }
    }
</script>

<style>
</style>

<style scoped>


    .botao-clienteAdd.add-cli {
        background-color: transparent;
        color: var(--cinza-5);
        cursor: pointer;
        border: 0;
        outline-style: none;
    }

        .botao-clienteAdd.add-cli .fas.fa-plus {
            color: var(--cinza-5);
            margin-right: 3px;
            font-size: 14px;
        }

        .botao-clienteAdd.add-cli:before {
            border-top-color: var(--cinza-3);
        }

        .botao-clienteAdd.add-cli:hover, .botao-clienteAdd.add-cli:focus {
            background-color: transparent;
        }

            .botao-clienteAdd.add-cli:hover .fas.fa-plus, .botao-clienteAdd.add-cli:focus .fas.fa-plus {
                color: var(--cor-primaria-cliente);
            }

            .botao-clienteAdd.add-cli:hover:before, .botao-clienteAdd.add-cli:focus:before {
                border-top-color: var(--cinza-4);
            }

    .ajuste_dropdown > p {
        padding: 0;
        border: 0px;
        border-radius: 0px;
    }

    .i_modal_orcamento {
        color: #878787;
        float: left;
        cursor: pointer;
    }

        .i_modal_orcamento:hover {
            color: var(--cor-primaria-cliente);
        }

    .ajuste_dropdown .card {
        border: 0px;
        border-radius: 0px;
    }

    .btn_pipeline {
        border: 0px !important;
        border-radius: 0 !important;
        background-color: var(--cor-primaria-cliente);
        padding: 7px 21px;
        font-size: 12px;
    }

    #tab_kanban {
        padding-left: 2vh;
    }

    #colunas-kanban {
        height: 87%;
        max-height: 100vh;
        margin-left: 2vh;
        margin-right: 2vh;
        padding-left: 0;
        padding-bottom: .5%;
        background-color: var(--cinza-3);
    }

    #botaoInfoIcon:hover {
        color: var(--cor-primaria-cliente)
    }
    /*SCROLL BAR ==============*/

    .insert_scroll::-webkit-scrollbar {
        width: 4px;
        background-color: white;
        border-radius: 150%;
        margin-right: 30px;
    }

    .insert_scroll::-webkit-scrollbar-track {
        background-color: #adadad;
    }

    .insert_scroll::-webkit-scrollbar-thumb {
        background-color: #fff;
    }

    /*=========================*/

    #btn_pipeline {
        z-index: 1;
    }

    .kanban-tab-content {
        background-color: var(--cinza-3);
        height: calc(100%);
    }

    #kanbTab-openers > div {
        margin-right: .4vw;
        background-color: var(--cinza-2);
        cursor: pointer;
    }

        #kanbTab-openers > div.active {
            background-color: var(--cinza-3);
            cursor: default;
            vertical-align: middle;
        }

            #kanbTab-openers > div.active > span {
                text-shadow: .3px 0 var(--cinza-5), -.3px 0 var(--cinza-5);
            }

            #kanbTab-openers > div.active .kanbTab-add .fas.fa-plus {
                color: var(--cor-primaria-cliente);
            }

        #kanbTab-openers > div:hover > span {
            text-shadow: .3px 0 var(--cinza-5), -.3px 0 var(--cinza-5);
        }

        #kanbTab-openers > div .kanbTab-add {
            color: var(--cinza-4);
        }

        #kanbTab-openers > div:first-child .kanbTab-add {
            background-color: var(--cinza-3);
            cursor: default;
        }

            #kanbTab-openers > div:hover .kanbTab-add > i.fas.fa-plus, #kanbTab-openers > div:first-child .kanbTab-add > i.fas.fa-plus {
                color: var(--cor-primaria-cliente);
            }


    #kanban-navL-dashboard {
        max-width: 1690px;
        padding-left: 0px;
    }

    #kanbTab-managers {
        background-color: var(--cinza-3);
        cursor: default;
    }

    .container_principal {
        height: 91%
    }

    .kanban-navL-container {
        animation: fade-in .7s ease;
    }

    .botaoOrcamento {
        background: none;
        border: none;
        padding: 0;
    }

    .container-kanban {
        height: calc(100% - 5rem);
    }

    .btn {
        border-radius: 0px !important;
        width: 97.5%;
        margin: 0px auto;
    }

    /* Ajuste KANBAN*/
    .column-width {
        min-width: 320px;
        width: 200px;
        display: inline-table;
        border-radius: 20px;
        padding: 10px;
        height: calc(100% - 1vh)
    }
    /* Unfortunately @apply cannot be setup in codesandbox,
    but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
    .ghost-card {
        opacity: 0.5;
        background: #F7FAFC;
        border: 1px solid #4299e1;
    }

    .card_funil {
        cursor: grab;
    }

    .titulo_coluna_funil {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        width: 315px;
        padding-top: 1vh;
        color: #FFFF;
    }

    .qnt_itens_funil {
        display: block;
        font-weight: bold;
        font-size: 12px
    }

    #menu_kanban {
        background-color: var(--cinza-3);
        display: table;
        width: 100%;
        position: relative;
        height: 48px;
        padding: 2vh;
    }

    .insert_scroll {
        overflow-y: scroll;
        display: inline-block;
    }

    .card_canal {
        margin-top: 0px;
        padding-right: 4px;
    }

    .ajuste_coluna {
        width: 100%;
        height: 90%;
        position: relative;
        flex-direction: column;
        display: flex;
        margin-bottom: 16px;
        overflow-x: hidden;
    }

    #funil-de-vendas {
        position: relative;
        background-color: var(--cinza-3);
        height: 105%;
        animation: funnel-fade-in .7s ease;
    }

    .funnel-layer {
        display: table;
        margin: 10px auto;
        color: #fff;
        padding: 2px;
        font-size: 1.2vw;
        cursor: default;
        transition: all ease-in-out .3s;
    }

        .funnel-layer > div {
            width: 50%;
            padding: 15px;
        }

        .funnel-layer.available:hover {
            cursor: pointer;
            filter: brightness(1.1);
        }

    .funnel-title {
        float: left;
        text-align: right;
    }

    .menu-cards {
        margin-bottom: 0px;
        margin-left: 15px;
    }

    .funnel-info {
        position: relative;
        float: right;
        color: #373737;
        background-color: #fff;
    }

        .funnel-info > div {
            float: left;
            position: relative;
        }

            .funnel-info > div:before {
                position: absolute;
                top: -10px;
                font-size: 10px;
                font-weight: bold;
                color: var(--cor-primaria-cliente);
            }

    .funnel-info-quantity {
        width: 5vw;
    }

        .funnel-info-quantity:before {
            content: "Quantidade";
        }

    .funnel-info-amount {
        width: calc(100% - 5vw);
    }

        .funnel-info-amount:before {
            content: "Valor total"
        }

    #modo-visualizacao-invalido {
        width: 300px;
        cursor: default;
        text-align: center;
        color: #b51f1f;
        margin: 50px auto;
        padding: 7px 20px;
        border: 1px #b51f1f solid;
    }

    .icones_modals {
        margin-top: 10px;
        color: #878787;
        font-size: 1rem;
        align-items: center;
    }

    button.button {
        font-size: 12px;
        padding: 7px 21px;
    }

        button.button + button.button {
            margin-left: 10px;
        }

        button.button[disabled] {
            opacity: .7;
        }

        button.button:not([disabled]):hover {
            filter: brightness(1.1);
        }

        button.button:not([disabled]):active {
            filter: brightness(.95);
        }

        button.button.primary-button {
            border: 0;
            color: #fff;
            background-color: var(--cor-primaria-cliente);
        }

        button.button.secondary-button {
            border: 0;
            color: var(--cinza-5);
            background-color: #fff;
            border-bottom: 2px var(--cinza-5) solid;
        }

    @keyframes funnel-fade-in {
        from {
            padding-top: 0;
            opacity: 0;
        }

        to {
            padding-top: 20px;
            opacity: 1;
        }
    }
    /* card  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>   */
    #botaoTrocaIcon {
        cursor: default;
        padding: 5px
    }

    .card_kanban_protocolo {
        color: #14396b;
        font-size: 12px;
        font-weight: bold;
    }

    .select_color {
        color: white;
        float: right;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: transparent;
        border-radius: 100px;
        border-style: solid;
        border-color: white;
        cursor: pointer;
    }

    .conversa_funil {
        float: right;
        cursor: pointer;
        color: #878787;
        font-size: 1.2rem;
    }

    .card_funil_nome_cliente {
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #14396b;
        line-height: 25px;
    }

        .card_funil_nome_cliente:hover {
            text-decoration: underline;
            cursor: pointer;
        }

    .card_funil_nome_usuario {
        display: block;
        font-size: 12px;
        color: #878787;
    }

    .card_funil_data_inicio {
        display: block;
        font-size: 11px;
        color: #0fa590;
    }

    .card_funil_data_alterada {
        display: block;
        font-size: 11px;
        color: #c127f3
    }

    .nav-tab {
        background-color: var(--cinza-3);
    }

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #495057;
        background-color: var(--cinza-3);
        border-color: #dee2e6 #dee2e6 #fff;
    }

    .select_kanban {
        font-size: 12px;
        height: 35px;
        border: 0px;
        width: 100%;
        height: 4vh;
        background-color: #e8e8e8;
    }

    .abrir_modal_cli {
        margin-right: 10px;
        float: right;
    }

    .card_btn_acao {
        font-size: 12px;
        color: #FFF;
        background-color: #19b1bd;
        padding: 3px 9px;
        cursor: pointer;
    }

    .card-task {
        position: relative;
        cursor: grab;
        margin: 5px 5px;
        border-radius: 10px;
    }

    .protocolo_card {
        margin-top: -10px;
    }

    input, select, .input-search {
        display: inline-block;
        background-color: #fff;
        border: 0;
        outline-style: none;
        font-size: 14px;
        color: var(--cinza-5);
    }

    .input-search {
        position: relative;
    }

        .input-search > input[type=text] {
            width: 200px;
            padding: 1px;
        }

            .input-search > input[type=text]:focus {
                box-shadow: none;
            }

        .input-search > i.fa-user {
            font-size: 13px;
            margin-right: 5px;
        }

            .input-search > i.fa-user + input[type=text] {
                width: 184px;
            }

        .input-search > i:not(.fa-circle-notch) {
            cursor: pointer;
        }
</style>