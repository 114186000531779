<template>

	<div class="container-input-color">

        <div>
            <label class="m-0"> {{ label }} </label>
        </div>

        <div class="mb-3">
            <input type="color"
                   class="caixa-input-color p-0"
                   v-model="corHexa"
                   @input="$emit('change', $event.target.value)" />

            <input type="text" class="caixa-input-text" v-model="corHexa" @input="$emit('change', $event.target.value)" />

        </div>

	</div>

</template>

<script>
	export default {
        name: 'inputColor',
        props: {
            label: { String, default: '' },
            cor: { String, default: '#000000' },
        },
        computed: {
            corHexa: {
                get() {
                    return this.cor
                },
                set(value) {
                    return value
                }
            }
        },
    }
</script>


<style scoped>
    .container-input-color {
        position: relative;
        width: 100%;
    }

    .caixa-input-color {
        position: absolute;
        top: 19px;
        left: 3px;

        width: 24px;
        height: 26px;
        background-color: transparent;
        border: 0;
    }

    .caixa-input-text {
        width: 100%;
        padding-left: 2rem;
        border: 0;
        border-bottom: 1px solid black;
    }

        .caixa-input-color:focus, .caixa-input-color:hover, .caixa-input-text:hover, .caixa-input-text:focus {
            outline: none;
        }
</style>
