<template>
    <b-modal id="orcamento-kanban" scrollable ref="modalOrcamento" centered size="xl" @hide="fecharModal" style="z-index: 200 !important;">
        <template #modal-header="{ cancel }">
            <div>
                <div><span class="tituloModal">Orçamento</span></div>
                <span class="subtituloModal">Protocolo #{{ dadosCard.Protocolo }}</span>
            </div>
            <i class="fas fa-times" @click="cancel" style="cursor:pointer"></i>
        </template>
        <center  v-if="buscarOrcamentoTimer">
            <i class="fas fa-circle-notch fa-spin carregandoOrc mx-2" small ></i>
            <label class="carregandoOrc"> Carregando ...</label>
        </center>
        <b-container v-else>
            <b-row v-for="(item, index) in itensOrcamento" :key="item.key" class="data-field" align-v="center">
                <b-col cols="2" class="data-field p-2">
                    <label>Cod.</label>
                    <div id="displayInputSKU" class="data-field my-0 insider-icon" cols="6">
                        <i class="fas fa-circle-notch fa-spin" :id="`${index}SKU`" v-if="(searchProdutosTimer || ProdutosRequest) && !item.SKU && item._loader == 'sku'" small style="margin-left:7px;"></i>
                        <i v-else-if="!item.ProdutoId" class="fas fa-search" ></i>
                        <input type="text" :class="`${item.Id || item.ProdutoId ? 'data-field text-info font-weight-bold my-0 insider-icon' : 'data-field my-0 insider-icon'}`"
								   :readonly="item.Id || item.ProdutoId  != null" v-model="ProdutosQuerySKU[index]" @keyup="searchProdutos(index, 'sku')" placeholder="Cod. produto"/>
                    </div>
                </b-col>

                <b-col cols="6" class="data-field">
                    <label class="">Produto</label>
                    <div id="displayInputNome" class="data-field my-0 insider-icon" cols="6">
                        <i class="fas fa-circle-notch fa-spin" :id="`${index}Nome`" v-if="(searchProdutosTimer || ProdutosRequest) && !item.Nome && item._loader == 'nome'" small ></i>
                        <i v-else-if="!item.ProdutoId" class="fas fa-search"></i>
							<input id="inputTexto" type="text"
								   ref="input-search"
								   placeholder="Nome ou referência do produto"
								   autocomplete="off"
								   v-model="ProdutosQueryNome[index]"
								   :class="`${item.Id || item.ProdutoId ? 'data-field text-info font-weight-bold my-0' : 'data-field my-0'}`"
								   :readonly="item.Id != null || item.ProdutoId != null" @keyup="searchProdutos(index, 'nome')" />
							<div class="search-results app-scroll-custom" v-if="Produtos && item.ProdutoId == null">
                                {{ item.SKU }}
								<span v-if="Produtos.length <= 0 && QueryOrcamento.Query != null" class="text-nowrap font-weight-bold small px-2 mx-2" style="opacity:0.5">
									Nenhum produto encontrado.
								</span>
								<div v-for="produto in Produtos" :key="produto.Id" @click="selectProdutos(index,produto)">
									<div class="text-nowrap font-weight-bold text-info">
										<i class="fas fa-box mr-1"></i>
										{{produto.Nome}}
									</div>
									<div class="text-nowrap small">
										<div v-if="produto.SKU">
											<b>SKU:</b>
											{{produto.SKU}}
										</div>
										<div v-if="produto.Referencia">
											<b>Referencia:</b>
											{{produto.Referencia}}
										</div>
									</div>
								</div>
							</div>
						</div>
                </b-col>
                <b-col cols="1" class="data-field p-2">
                    <label>Qntd.</label>
                    <input type="number" @keyup="numerico" min="1" v-model="item.Quantidade"/>
                </b-col>

                <b-col cols="1" class="data-field p-2">
                    <label>Desc. %</label>
                    <input type="number" @keyup="numerico" min="0" max="100" v-model="item.Desconto" />
                </b-col>

                <b-col cols="2" class="data-field  mt-3">
                    <label class="labelPreco" style="font-size:15px;">
                        R$ {{(!item.Valor ? 0 :  ( item.Valor * item.Quantidade)-((item.Valor * item.Quantidade)*(item.Desconto * 0.01))).toFixed(2) }}
                    </label>
                    <i class="fas fa-trash-alt float-left cursor-pointer float-right" @click="removerItem(index)" v-if="item.ProdutoId"></i>
                </b-col>
            </b-row>

            <b-row align-v="center" style="margin-right:13px">
                <b-col cols="8" class="data-field p-2">
                </b-col>
                <b-col cols="2" class="data-field p-2">
                    <label>Desconto total%</label>
                    <input type="number" min="0" max="100" v-model="DescontoTotal"/>
                </b-col>

                <b-col cols="2" class="data-field  mt-3">
                    <label class="labelPreco" style="font-size:15px;">
                        R$ {{ CalcularvalorTotal}}
                    </label>
                </b-col>
            </b-row>
        </b-container>

        <!--BOTÃO ADICIONAR ITEM =============================-->



        <!--BOTOES FOOTER MODAL =============================-->
        <template #modal-footer="{ cancel }">

            <b-button variant="light" @click="cancel()" class="rounded-0">Cancelar</b-button>

            <b-button variant="success" @click="salvarOrcamento()" :disabled="!verifySave" class="rounded-0">Salvar</b-button>

        </template>

    </b-modal>

</template>

<script>
    import axios from "axios";
    
    export default{

        name: 'modalOrcamento',

        data(){


            return {
                orcamentoId: null,
                Produtos:null,
                valorTotal: 0,
                DescontoTotal: 0,
                ProdutosRequest:null,
                searchProdutosTimer: null,
                buscarOrcamentoTimer: null,
                isSelected: false,
                dadosCard: {
                    Protocolo:null,
                    AtendimentoId: null
                },
                ProdutosQueryNome: [],
                ProdutosQuerySKU: [],
                QueryOrcamento:{
                    Query:''
                },
                promiseSalva: false,
                promiseEdita: false,
                promiseDeleta: false,
                itensOrcamento: [],
                lixoIds: []
            };


        },
        watch:{
        },
        computed: {
            excluirHabilitado() {
                return this.itensOrcamento.length > 1;
            },
            verifySave() {
                return this.itensOrcamento.filter(item => item.ProdutoId).length > 0 || this.orcamentoId;
            },
            CalcularvalorTotal(){
                let total = this.itensOrcamento.reduce((total, item) => total + (item.Valor * (1 - parseFloat(item.Desconto) * .01) * item.Quantidade), 0);

                // this.itensOrcamento.map(item =>{
                //     total += item.Valor * (1-(item.Desconto * 0.01));
                // })

                total *= 1 - this.DescontoTotal * .01;
                return total?.toFixed(2)
            }
        },
        methods: {
            getSymbol() {
                return Symbol();
            },
            iniciarModal(dadosCard) {
                $("#caixa_carregamento").show();
                this.$bvModal.show("orcamento-kanban");
                this.itensOrcamento = [];
                this.adicionarItem();
                
                this.dadosCard = dadosCard;
                this.buscarOrcamento(dadosCard);
                console.log(dadosCard)
                const promise1 = new Promise(resolve => this.promiseSalva = resolve);
                const promise2 = new Promise(resolve => this.promiseEdita = resolve);
                const promise3 = new Promise(resolve => this.promiseDeleta = resolve);
                return Promise.all([promise1, promise2, promise3]).then((values) => {
                    //console.log(values);
                });

            },

            adicionarItem() {
                this.itensOrcamento.push({ SKU: null, Nome: null, Quantidade: 1, Desconto: 0, Valor: 0, _loader: null, key:Symbol() });
            },
            removerItem(index) {

                this.$bvModal.msgBoxConfirm("Deseja excluir o produto?", {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        
                        if (this.itensOrcamento[index].Id) {
                            this.lixoIds.push(this.itensOrcamento[index].Id);
                        }
                        if (this.itensOrcamento.length > 1) {
                            this.ProdutosQueryNome.splice(index,1)
                            this.ProdutosQuerySKU.splice(index,1)
                            this.itensOrcamento.splice(index, 1);
                        }
                        else if (this.itensOrcamento.length == 1) {
                            this.clearCampos(index)
                        }
                    }
                })
            },

            searchProdutos(index, loader) {
                this.itensOrcamento[index]._loader = loader;
                this.QueryOrcamento.Query = this.ProdutosQueryNome[index] ?? this.ProdutosQuerySKU[index];
				if (this.searchProdutosTimer) clearTimeout(this.searchProdutosTimer);
				if (this.ProdutosRequest) this.ProdutosRequest.cancel();
				if (this.itensOrcamento[index]?.SKU) return;
				if (!this.QueryOrcamento.Query?.trim()) {
					this.Produtos = null;
					this.searchProdutosTimer = null;
					this.ProdutosRequest = null;
					return;
				}
				this.searchProdutosTimer = setTimeout(() => {
					this.ProdutosRequest = axios.CancelToken.source();
					axios.post(`/api/produto/busca-produtos-search`,this.QueryOrcamento).then(response => {
						this.Produtos = response.data.Result;
						this.searchProdutosTimer = null;
						this.ProdutosRequest = null;
					}).catch(() => {
						this.Produtos = null;
						this.searchProdutosTimer = null;
						this.ProdutosRequest = null;
					});
				}, 300);
			},
			selectProdutos(index,produto) {
                //{ SKU: null, Nome: "", Quantidade: 1, Desconto: 0, Valor: 0 }
				this.itensOrcamento[index].SKU = produto.SKU;
				this.itensOrcamento[index].Nome = produto.Nome;
				this.itensOrcamento[index].Valor = produto.Valor;
                this.itensOrcamento[index].Desconto = 0;

                this.itensOrcamento[index].ProdutoId = produto.Id;
                this.itensOrcamento[index].Quantidade = 1;
				this.ProdutosQueryNome[index] = produto.Nome?.trim();
				this.ProdutosQuerySKU[index] = produto.SKU;
                this.Produtos = null;
                this.isSelected = true;
                this.adicionarItem()
			},
			clearCampos(index) {
				this.ProdutosQueryNome[index] = null;
				this.ProdutosQuerySKU[index] = null;
                this.QueryOrcamento.Query = "";
				this.Produtos = null;
				this.itensOrcamento[index].SKU = null;
				this.itensOrcamento[index].Id = null;
				this.itensOrcamento[index].Nome = null;
				this.itensOrcamento[index].Valor = 0;
                this.itensOrcamento[index].Desconto = 0;
                this.itensOrcamento[index].Quantidade = 1;
			},
            clearProdutos(){
                this.dadosCard = [];
                this.ProdutosQueryNome = [];
				this.ProdutosQuerySKU = [];
                this.QueryOrcamento.Query = null;
				this.Produtos = [];
				this.itensOrcamento = [];
            },
            salvarOrcamento(){
                this.itensOrcamento.pop();

                var itensSemId = this.itensOrcamento.filter(function(item) {
                    return !item.hasOwnProperty('Id');
                });
                var itensComId = this.itensOrcamento.filter(function(item) {
                    return item.hasOwnProperty('Id');
                });

                var orcamentoSalvarcomId = {
                    AtendimentoId: this.dadosCard.Id,
                    DescontoTotal: this.DescontoTotal,
                    OrcamentoProdutos: itensComId,
                    Id: this.orcamentoId
                }

                var orcamentoSalvarsemId = {
                    AtendimentoId: this.dadosCard.Id,
                    DescontoTotal: this.DescontoTotal,
                    OrcamentoProdutos: itensSemId,
                    Id: this.orcamentoId
                }       
                if(this.lixoIds.length > 0){
                    this.lixoIds.forEach(id=>{
                        axios.delete(`/api/produto/deletaProdutoOrcamento/${id}`).then(res =>{
                            this.promiseDeleta(true);
                        }).catch(res => {
                            console.log(res)
                        })
                    })
                }
                else{
                    this.promiseDeleta(true);
                }
                if(itensSemId.length > 0 && !this.orcamentoId){
                    axios.post(`/api/produto/salva-orcamento`,orcamentoSalvarsemId).then(response => {
                        this.promiseSalva(true);
					}).catch(() => {
						console.log(response)
					}); 
                }else if(itensSemId.length >0){
                    axios.post(`/api/produto/salva-orcamento-prod/`,orcamentoSalvarsemId).then(response =>{
                        this.promiseSalva(true);
                    }).catch(() => {
                        console.log(response)
                    })
                }
                else{
                    this.promiseSalva(true);
                }


                if(itensComId.length != 0){
                    axios.put(`/api/produto/edita-orcamento`,orcamentoSalvarcomId).then(response => {
                        this.promiseEdita(true);
					}).catch(() => {
						console.log(response)
					});      
                }
                else{
                    this.promiseEdita(true);
                }

                //this.$emit('salvarOrcamento');

                this.fecharModal();
            },
            numerico(event) {
                const isBackspace = event.key === 'Backspace';
                const isSelection = event.key === 'a' && (event.ctrlKey || event.metaKey); 

                if (!isBackspace && !isSelection) {
                    const invalidCharsRegExp = /[-+eE]/;
                    if (invalidCharsRegExp.test(event.key)) {
                        event.preventDefault();
                    }
                }
            },
            buscarOrcamento(dadosCard){
                this.buscarOrcamentoTimer = true;
                    axios.get(`/api/produto/busca-orcamento/`+dadosCard.Id).then(response => {	
                        this.DescontoTotal = response.data.Orcamento?.DescontoTotal ?? 0;	
                        this.itensOrcamento = response.data.Orcamento?.OrcamentoProdutos ?? [];
                        this.orcamentoId = response.data.Orcamento?.Id ?? null;
                        var listaProds = response.data.Orcamento?.OrcamentoProdutos ?? [];

                        listaProds.forEach(prod => {
                            this.ProdutosQueryNome.push(prod.Produto.Nome); 
                            this.ProdutosQuerySKU.push(prod.Produto.SKU);
                        })
                        this.adicionarItem();
                        $("#caixa_carregamento").hide();
                        this.buscarOrcamentoTimer = null;
                        }).catch((response) => {
                            console.log(response)
                            $("#caixa_carregamento").hide();
                            this.buscarOrcamentoTimer = null;
                    });
            },
            fecharModal() {
                this.$bvModal.hide("orcamento-kanban");
                this.clearProdutos();
            },
        }
    }

</script>

<style scoped>

    .carregandoOrc{
        color: #737373;
        font-size: 1.1rem;
    }
    .modal{
        z-index: 150 !important;
    }
    #orcamento-kanban input[type=text] {
        background-color: #F5F2F2;
        border: 1px 1px 0px 1px #737373 solid;
    }    
    #orcamento-kanban input[type=number] {
        background-color: #F5F2F2;
        border: 1px #CCCCCC solid;
    }

    #orcamento-kanban input[type=text]:focus {
        background-color: #F5F2F2;
    }

    #orcamento-kanban{
        z-index: 200 !important;
    }

    .data-field {
        color: #737373;
        margin-bottom:0;
    }

    .labelPreco {
        font-size:15px;
    }

    .excluirBtn{
        border:none;
        background:none;
        padding-right:0;
    }

    .excluirBtn:disabled{
        opacity: 0.5;
    }


    .addItenBtn {
        margin-top: 25px;
        background: none;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        border: 2px solid gray;
        border-radius: 50px;
        color: #737373;
    }
	.input-search {
		position: relative;
	}
        .input-search > input[type=text]:focus {
            box-shadow: none;
        }

		.input-search > i:not(.fa-circle-notch) {
			cursor: pointer;
		}

    #orcamento-kanban___BV_modal_outer_{
        z-index: 200!important;
    }

	.search-results {
		position: absolute;
		min-width: 97%;
		max-height: 50vh;
		top: calc(100% + 3px);
		left: -1px;
		background-color: #fff;
		z-index: 200 !important;
		overflow-x: hidden;
		animation: ease-in slide-down .3s;
	}

		.search-results > div {
			border-bottom: 1px var(--cinza-3) solid;
			cursor: pointer;
			transition: all ease-in-out .3s;
            margin-left: 1rem;
		}

			.search-results > div:hover {
				background-color: var(--cinza-1);
			}
    input, select, .input-search {
		display: inline-block;
		background-color: #fff;
		border: 0;
		outline-style: none;
		font-size: 14px;
		color: var(--cinza-5);
	}
    #displayInputSKU{
        display: flex;
        align-items: center;
        background-color: #F5F2F2;
    }   
    #displayInputNome{
        display: flex;
        align-items: center;
        background-color: #F5F2F2;
    }
    .tituloModal{
        font-size: 1.5rem;
        font-weight: bold;
        color: var(--cor-primaria-cliente);
    }
    .subtituloModal{
        color: var(--cor-primaria-cliente);
    }
</style>